.login-page-container {
  background-image: url("./images/login-background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #284b63;
  min-height: 100vh;
}
.form-container {
  background-color: #d9d9d9;
  border: 2px solid #353535;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 90%;
}

.company-name {
  font-family: "Bebas Neue";
  font-size: 36px;
  color: #284b63;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.company-name-navbar {
  font-family: "Bebas Neue";
  font-size: 48px;
  color: #284b63;
  padding-top: 0px;
  margin: 0px;
}

.nav-button {
  margin-left: 12px;
  margin-right: 12px;
}

.form-title {
  font-family: "Bebas Neue";
  font-size: 24px;
  color: #353535;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.form-label {
  font-family: "Roboto";
  color: #353535;
  padding: 4px;
  margin: 0px;
  font-size: 20px;
}

form input {
  width: 95%;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: "Roboto";
  font-size: 16px;
}

form textarea {
  width: 95%;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: "Roboto";
  font-size: 16px;
}

form select {
  width: 95%;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: "Roboto";
  font-size: 16px;
}

.form-item {
  width: 90%;
  margin: 12px;
}

.form-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.submit-button {
  background-color: #284b63;
  color: #ffffff;
  border: 2px solid #353535;
  border-radius: 4px;
  width: 120px;
  text-align: center;
  height: 30px;
  font-family: "Bebas Neue";
  font-size: 20px;
  margin: 10px;
}

.cancel-button {
  background-color: #d9d9d9;
  color: #353535;
  border: 2px solid #353535;
  border-radius: 4px;
  width: 120px;
  text-align: center;
  height: 30px;
  font-family: "Bebas Neue";
  font-size: 20px;
  margin: 10px;
}

.delete-button {
  background-color: rgb(132, 5, 5);
  color: #ffffff;
  border: 2px solid #353535;
  border-radius: 4px;
  width: 120px;
  text-align: center;
  height: 30px;
  font-family: "Bebas Neue";
  font-size: 20px;
  margin: 10px;
}

.link {
  text-align: right;
  font-family: "Roboto";
  color: #284b63;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

#dash-selector {
  max-width: 1200px;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  border-radius: 24px;
  margin-bottom: 20px;
}

#dash-selector a {
  width: 50%;
}

.picture-link-orders {
  background-image: url("./images/order-image.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 500px;
  border: 2px solid #353535;
  border-radius: 24px 0px 0px 24px;
}

.picture-link-components {
  background-image: url("./images/components-image.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 500px;
  border: 2px solid #353535;
  border-radius: 0px 24px 24px 0px;
}

.dash-title {
  font-family: "Bebas Neue";
  font-size: 72px;
  text-align: center;
  color: #ffffff;
  text-shadow: -2px -2px 0 #353535, 2px -2px 0 #353535, -2px 2px 0 #353535,
    2px 2px 0 #353535;
}

.dash-title-container {
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dash-text {
  background-color: #d9d9d9;
  border: 2px solid #353535;
  margin: 8px;
  border-radius: 12px;
  text-align: center;
  font-family: "Roboto";
  color: #353535;
}

.navbar {
  display: flex;
  border: 2px solid #353535;
  border-radius: 24px;
  flex-direction: row;
  margin: 24px;
  align-items: center;
  justify-content: space-between;
  background-color: #d9d9d9;
  width: 90%;
  padding: 24px;
}

.loading-splash {
  display: flex;
  background-color: #d9d9d9;
  border: 2px solid #353535;
  border-radius: 24px;
  flex-direction: row;
  margin: 24px;
  align-items: center;
  padding: 24px;
  justify-content: center;
}

.loading-text {
  font-family: "Bebas Neue";
  font-size: 36px;
  color: #284b63;
  padding: 0px;
  margin: 0px;
}

td {
  border: 1px solid #353535;
  background-color: #ffffff;
  padding: 8px 12px 8px 12px;
  text-align: center;
  font-family: "Roboto";
  max-width: 250px;
  max-height: 50px;
}

th {
  border: 1px solid #353535;
  background-color: #d9d9d9;
  padding: 12px;
  color: #353535;
  font-family: "Roboto";
}

table {
  border-collapse: collapse;
  margin: 10px;
  border: 2px solid #353535;
}

.page-title-container {
  width: 80%;
}

.page-title {
  width: 100%;
  text-align: left;
  font-family: "Bebas Neue";
  font-size: 48px;
  color: #ffffff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background: #d9d9d9;
  padding: 20px;
  border: 2px solid #353535;
  border-radius: 24px;
  min-width: 300px;
  overflow-y: auto;
  overflow-x: auto;
}

.settings-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table-button {
  background-color: #d9d9d9;
  color: #353535;
  border: 2px solid #353535;
  border-radius: 8px;
  width: 240px;
  text-align: center;
  height: 60px;
  font-family: "Bebas Neue";
  font-size: 24px;
  margin: 10px;
}

.table-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

form p {
  font-family: "Roboto";
}

.new-user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #353535;
  margin-bottom: 10px;
  border-radius: 8px;
}

.new-user-details p {
  margin-top: 4px;
  margin-bottom: 4px;
}

@media (max-width: 600px) {
  .navbar {
    flex-direction: column;
    margin: 8px;
    width: 85%;
    padding: 8px;
  }

  .page-title {
    font-size: 32px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  #dash-selector {
    width: 80%;
    flex-direction: column;
  }

  #dash-selector a {
    width: 100%;
  }

  .dash-title {
    font-size: 60px;
  }

  .dash-title-container {
    min-height: 200px;
  }

  .picture-link-orders {
    min-height: 250px;
    border-radius: 24px 24px 0px 0px;
  }

  .picture-link-components {
    min-height: 250px;
    border-radius: 0px 0px 24px 24px;
  }

  .nav-button {
    max-height: 50px;
  }

  .table-buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .table-button {
    max-width: 150px;
    margin: 4px;
  }

  .table-container {
    width: 382px;
    overflow-y: auto;
    height: 500px;
    overflow-x: auto;
  }

  td {
    padding: 4px 6px 4px 6px;
    font-size: 12px;
  }

  th {
    padding: 4px;
    font-size: 12px;
  }
}
